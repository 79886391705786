$primary: #000;

/* MAIN COLOR */

$secondary: #0e76bc;

/* SECONDARY COLOR */

$gray: #666;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$footerLinks: #d5d5d5;

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

p,
ol,
ul {
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 1.25em;
    letter-spacing: 1px;
}

h1 {
    font-family: 'Fjalla One', sans-serif;
    font-size: 5em;
    @media (max-width: 767px) {
        font-size: 4em;
    }
}

h2 {
    font-family: 'Fjalla One', sans-serif;
    font-size: 3.5em;
    @media (max-width: 767px) {
        font-size: 3em;
    }
}

h3 {
    font-family: 'Fjalla One', sans-serif;
    font-size: 2.75em;
    @media (max-width: 767px) {
        font-size: 2.5em;
    }
}

h4 {
    font-family: 'Fjalla One', sans-serif;
    font-size: 2em;
}

nav {
    z-index: 10;
}

.navbar-default {
    height: 65px;
    border-bottom: 2px solid $secondary;
}

.navbar-brand {
    height: 100px; // this is what determine the height of the nav
    padding: 5px;
    @media(max-width: 767px) {
        height: 60px; // nav height mobile,(feel free to change this values as you please)
        .logo {
            max-height: 60px; // logo height changed on mobile
        }
    }
}

.navbar .navbar-nav {
    >li>a {
        text-align: center;
        margin-top: 10px;
        display: flex;
        align-items: center;
        color: black;
        transition: 1s all ease;
        @media (max-width: 767px) {
            margin-top: 0;
            display: inline-block;
            height: 25px;
        }
        &:hover {
            background: transparent;
            color: $secondary;
            @media (max-width: 1024px) {
                background: transparent;
                color: $blk;
                outline: 0;
            }
        }
        &:focus,
        &:active {
            background: transparent;
            color: $blk;
            outline: 0;
        }
    }
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

#navbar-collapse {
    background: $wht;
    @media (min-width: 767px) {
        background: transparent;
    }
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn {
    text-align: center;
    margin: 0 auto;
    border: 1px solid $primary;
    border-radius: 0px;
    background: $primary;
    color: $wht;
    padding: 0.5em 1em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 2em 0em;
    &:hover {
        background: lighten($primary, 10%);
        color: $wht;
        box-shadow: 0px 0px 3px lighten($blk, 20%);
        text-decoration: none;
    }
}

.btn-default {
    @include btn;
}

// mixins end here
// sections start
.section-a {
    padding: 60px;
    margin-top: 100px; // this marging  will depend on the height of the nav, (if you have a bigger or smaller nav feel free to change it)  'if you need a transparent nav and see the img change margin to padding'
}

// sections end
.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    a.btn,
    a.btn-default,
    button {
        @include btn margin: 0;
        display: block;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $footerLinks;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

.modal-content h2 {
    font-size: 1.65em;
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: $secondary;
    border-color: $wht;
    color: $primary;
    padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download:hover {
    background-color: $primary;
    color: $wht;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    margin-top: 15px;
}

.btn-download-2,
.btn-download-2:focus,
.btn-download-2:active,
.btn-download-2:visited {
    background-color: $secondary;
    border-color: $wht;
    color: $primary;
    padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download-2:hover {
    background-color: $primary;
    color: $wht;
}

.btn-download-2 {
    margin-bottom: 15px !important;
    display: block;
    // margin: 0 auto;
    max-width: 175px;
    margin-top: 15px;
    @media (max-width: 1024px) {
        margin: 0 auto;
    }
}


/* ========== HEADER ============ */

.header {
    background: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    margin-top: 65px;
}

.header-overlay {
    background: rgba(31, 31, 31, .5);
    padding: 25px 0px;
}

.header h1 {
    color: $wht;
    font-weight: bold;
    margin-top: 250px;
    @media (max-width: 1199px) {
        margin-top: 45px;
    }
    @media (max-width: 1024px) {
        margin-top: 25px;
    }
}

.header p {
    color: $wht;
}

.header img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 550px;
}


/* ========== SECTION ONE ============ */

.section-one {
    padding: 50px 0px;
}

.section-one i {
    font-size: 100px;
    color: $secondary;
}


/* ========== SECTION TWO ============ */

.section-two {
    background: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    @media (max-width: 1024px) {
        text-align: center;
    }
}

.section-two-overlay {
    background: rgba(31, 31, 31, .5);
    padding: 30px 0px;
}

.section-two h2 {
    color: $wht;
    font-weight: bold;
    margin-top: 85px;
    @media (max-width: 1199px) {
        margin-top: 45px;
    }
}

.section-two p {
    color: $wht;
}

hr {
    margin: 0px;
}

.section-two img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 550px;
}


/* ========== SECTION THREE ============ */

.section-three {
    padding: 75px 0px;
}


/* ========== SECTION FOUR ============ */

.section-four {
    background: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    @media (max-width: 1024px) {
        text-align: center;
    }
}

.section-four-overlay {
    background: rgba(31, 31, 31, .5);
    padding: 50px 0px;
}

.section-four h2 {
    color: $wht;
    font-weight: bold;
}

.section-four p {
    color: $wht;
}

.section-four img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 550px;
}

hr {
    margin: 0px;
}